import { useUser } from '@clerk/nextjs'
import Image from 'next/image'
import Link from 'next/link'

export default function Footer({}) {
  const { user } = useUser()
  const isSubscribed = user?.publicMetadata.isSubscribed
  const onboarded = user?.publicMetadata.onboarded

  const linkDest = () => {
    if (!user) {
      return '/'
    } else {
      if (!isSubscribed) {
        return '/'
      } else {
        if (!onboarded) {
          return '/onboarding'
        } else {
          return '/dashboard'
        }
      }
    }
  }

  return (
    <footer className="bg-white dark:bg-gray-900  border-t pt-6">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <Link rel="canonical" href={linkDest()} className="flex justify-center w-fit">
              <Image
                className="object-scale-down h-8 w-44"
                priority={true}
                src="/images/logo.png"
                alt="Logo"
                width={1000}
                height={1000}
              />
            </Link>
          </div>

          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-4">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Follow us
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li>
                  <Link
                    href="https://www.linkedin.com/company/v0-report/"
                    className="hover:underline "
                  >
                    LinkedIn
                  </Link>
                </li>
                <li>
                  <Link href="https://www.instagram.com/v0.report/" className="hover:underline ">
                    Instagram
                  </Link>
                </li>
                <li>
                  <Link href="https://www.youtube.com/@v0.report" className="hover:underline ">
                    YouTube
                  </Link>
                </li>
                <li>
                  <Link href="https://twitter.com/v0report" className="hover:underline ">
                    Twitter
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Resources
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li>
                  <Link href="/pricing" className="hover:underline">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link href="/affiliate" className="hover:underline">
                    Affiliates
                  </Link>
                </li>
                <li>
                  <Link href="/privacy" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link href="/terms-of-service" className="hover:underline">
                    Terms &amp; Conditions
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Services{' '}
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium grid gap-1">
                <li>
                  <Link href="/tools/report-generator" className="hover:underline">
                    Report generator
                  </Link>
                </li>
                <li>
                  <Link href="/tools/research-paper-generator" className="hover:underline">
                    Research paper generator
                  </Link>
                </li>
                <li>
                  <Link href="/tools/literature-review-generator" className="hover:underline">
                    Literature review generator
                  </Link>
                </li>
                <li>
                  <Link href="/tools/essay-generator" className="hover:underline">
                    Essay generator
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <div className="md:my-10" />
              <ul className="text-gray-500 dark:text-gray-400 font-medium grid gap-1">
                <li>
                  <Link href="/tools/sentence-rewriter" className="hover:underline">
                    Sentence Rewriter
                  </Link>
                </li>
                <li>
                  <Link href="/tools/pdf-summarizer" className="hover:underline">
                    PDF Summarizer
                  </Link>
                </li>
                <li>
                  <Link href="/tools/book-summarizer" className="hover:underline">
                    Book Summarizer
                  </Link>
                </li>
                <li>
                  <Link href="/tools/paragraph-generator" className="hover:underline">
                    Paragraph Generator
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2024 Davion Group Limited. All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              Email us at <span className="hover:underline">team@v0.report</span>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}
