import { TinyLoader } from '@/components/loaders'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { trpc } from '@/utils/trpc'
import { SignedIn, SignedOut, UserButton, useUser } from '@clerk/nextjs'
import {
  Book,
  ChevronDown,
  CircleDollarSign,
  CreditCard,
  Home,
  LogIn,
  Menu,
  UserRoundPlus,
} from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { toast } from 'sonner'

const TopNav = () => {
  const router = useRouter()
  const { user } = useUser()

  const { isLoading, mutateAsync } = trpc.getBillingPortalUrl.useMutation(undefined)

  const isSubscribed = user?.publicMetadata.isSubscribed

  return (
    <div className="border-b border-border-zinc-100 select-none py-6 px-10 md:px-24 z-10 w-full items-center justify-between font-mono text-sm lg:flex grid grid-cols-2">
      <div className="flex items-center gap-4">
        <Link
          rel="canonical"
          href={isSubscribed ? '/home' : '/'}
          className="flex justify-center w-fit"
        >
          <Image
            className="object-scale-down h-8 w-8"
            src="/images/logo.png"
            alt="Logo"
            width={200}
            height={300}
          />
          <p className="my-auto ml-4 hidden sm:block bg-gray-100 text-gray-600 px-3 py-1.5 rounded ">
            v0 report
          </p>
        </Link>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button variant="neutralLink">
              Tools <ChevronDown className="w-4 h-4 ml-1 hover" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="text-left grid gap-2">
            <DropdownMenuItem>
              <Button variant={'navGhost'} className="hover:mouse-pointer">
                <Link href="/tools/report-generator">
                  <p className="text-left w-full my-2">
                    <span className="font-semibold text-zinc-700 text-base">Report Generator</span>
                    <br />
                    <span className="font-medium text-zinc-600">
                      Tool for detailed, accurate, and insightful reports
                    </span>
                  </p>
                </Link>
              </Button>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Button variant={'navGhost'} className="hover:mouse-pointer">
                <Link href="/tools/research-paper-generator">
                  <p className="text-left w-full my-2">
                    <span className="font-semibold text-zinc-700 text-base">
                      Research Paper Generator
                    </span>
                    <br />
                    <span className="font-medium text-zinc-600">
                      Generate high-quality research papers for any topic
                    </span>
                  </p>
                </Link>
              </Button>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Button variant={'navGhost'} className="hover:mouse-pointer">
                <Link href="/tools/literature-review-generator">
                  <p className="text-left w-full my-2">
                    <span className="font-semibold text-zinc-700 text-base">
                      Literature Review Generator
                    </span>
                    <br />
                    <span className="font-medium text-zinc-600">
                      For efficient literature paper creation
                    </span>
                  </p>
                </Link>
              </Button>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Button variant={'navGhost'} className="hover:mouse-pointer">
                <Link href="/tools/essay-generator">
                  <p className="text-left w-full my-2">
                    <span className="font-semibold text-zinc-700 text-base">Essay Generator</span>
                    <br />
                    <span className="font-medium text-zinc-600">
                      Create essays in just a few seconds
                    </span>
                  </p>
                </Link>
              </Button>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Button variant={'navGhost'} className="hover:mouse-pointer">
                <Link href="/tools/sentence-rewriter">
                  <p className="text-left w-full my-2">
                    <span className="font-semibold text-zinc-700 text-base">
                      Sentence Rewriter{' '}
                    </span>
                    <br />
                    <span className="font-medium text-zinc-600">
                      Enhance the quality of any sentence in one click
                    </span>
                  </p>
                </Link>
              </Button>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Button variant={'navGhost'} className="hover:mouse-pointer">
                <Link href="/tools/pdf-summarizer">
                  <p className="text-left w-full my-2">
                    <span className="font-semibold text-zinc-700 text-base">PDF Summarizer</span>
                    <br />
                    <span className="font-medium text-zinc-600">
                      Summarizer tool for accurate PDF summaries
                    </span>
                  </p>
                </Link>
              </Button>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Button variant={'navGhost'} className="hover:mouse-pointer">
                <Link href="/tools/book-summarizer">
                  <p className="text-left w-full my-2">
                    <span className="font-semibold text-zinc-700 text-base">Book Summarizer</span>
                    <br />
                    <span className="font-medium text-zinc-600">Reads and summarize books</span>
                  </p>
                </Link>
              </Button>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Button variant={'navGhost'} className="hover:mouse-pointer">
                <Link href="/tools/paragraph-generator">
                  <p className="text-left w-full my-2">
                    <span className="font-semibold text-zinc-700 text-base">
                      Paragraph Generator
                    </span>
                    <br />
                    <span className="font-medium text-zinc-600">
                      Create high-quality well-written paragraphs
                    </span>
                  </p>
                </Link>
              </Button>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <Link href="https://blog.v0.report/" className="hidden sm:block">
          <Button variant={'zincLink'}>Blog</Button>
        </Link>
        <Link href={'/pricing'} className="hidden sm:block">
          <Button variant={'zincLink'}>Pricing</Button>
        </Link>
      </div>

      <div className="ml-auto">
        <SignedIn>
          <div className="w-fit flex items-center gap-4">
            {isSubscribed ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <button aria-label="Open Menu" className="cursor-pointer">
                    <Menu />
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-72 mr-12 py-4">
                  <DropdownMenuItem>
                    <Link
                      href="/home"
                      className="h-full w-full flex flex-row items-center p-0 m-0"
                      passHref
                    >
                      <Home className="mx-6 h-5 w-5 text-zinc-400 " />
                      <span>Home</span>
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={async () => {
                      toast.info('Redirecting to billing portal')
                      const data = await mutateAsync()

                      if (data?.billingPortalUrl) {
                        router.push(data?.billingPortalUrl)
                      } else {
                        toast.error(
                          "Sorry, we couldn't get you billing portal, please try again later."
                        )
                      }
                    }}
                  >
                    <CreditCard className="mx-6 h-5 w-5 text-zinc-400" />
                    <div
                    // href={billingPortalData?.billingPortalUrl ?? ""}
                    >
                      {isLoading ? <TinyLoader /> : <span>Billing</span>}
                    </div>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <Link href={'/home'}>
                <Button className="mr-4" variant={'zincLink'}>
                  Dashboard
                </Button>
              </Link>
            )}
            <UserButton afterSignOutUrl="/" />
          </div>
        </SignedIn>
        <SignedOut>
          <div className="sm:hidden block">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Menu />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-72 mr-12 py-4 grid gap-1 rounded-md p-1">
                <DropdownMenuItem className=" px-4 py-2 mt-1 ">
                  <CircleDollarSign className="ml-3 mr-6 text-zinc-400" />
                  <Link href="/pricing" className="w-full">
                    <span>Pricing</span>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem className=" px-4 py-2 mt-1 ">
                  <Book className="ml-3 mr-6 text-zinc-400" />
                  <Link href="https://blog.v0.report/" className="w-full">
                    <span>Blog</span>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem className=" px-4 py-2 mt-1 ">
                  <LogIn className="ml-3 mr-6 text-zinc-400" />
                  <Link href="/auth/signin" className="w-full">
                    <span>Sign in</span>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem className="bg-zinc-800 text-primary-foreground hover:bg-zinc-800/90 px-4 py-2 mt-1 ">
                  <LogIn className="ml-3 mr-6 text-zinc-400" />
                  <Link href="/auth/signup" className="w-full">
                    <span>Sign up</span>
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="flex items-center hidden sm:block">
            <Link href={'/auth/signin'}>
              <Button className="mr-4" variant={'zincLink'}>
                Sign in
              </Button>
            </Link>

            <Link
              className="h-8 rounded-md px-4 inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-sky-600 text-primary-foreground hover:bg-sky-600/90 "
              href={'/auth/signup'}
            >
              Sign up
            </Link>
          </div>
        </SignedOut>
      </div>
    </div>
  )
}

const TopNav2 = () => {
  const router = useRouter()
  const { user } = useUser()

  const { isLoading, mutateAsync } = trpc.getBillingPortalUrl.useMutation(undefined)

  const isSubscribed = user?.publicMetadata.isSubscribed

  return (
    <div className="border-b border-border-zinc-100 select-none py-6 px-24 z-10 w-full items-center justify-between font-mono text-sm lg:flex grid grid-cols-2">
      <div className="flex items-center gap-4">
        <Link
          rel="canonical"
          href={isSubscribed ? '/home' : '/'}
          className="flex justify-center w-fit"
        >
          <Image
            className="object-scale-down h-8 w-8"
            src="/images/logo.png"
            alt="Logo"
            width={200}
            height={300}
          />
          <p className="my-auto ml-4 hidden sm:block bg-gray-100 text-gray-600 px-3 py-1.5 rounded ">
            v0 report
          </p>
        </Link>
        <Link href="https://blog.v0.report/">
          <Button variant={'zincLink'}>Blog</Button>
        </Link>
        <Link href={'/pricing'}>
          <Button variant={'zincLink'}>Pricing</Button>
        </Link>
      </div>

      <div className="ml-auto">
        <SignedIn>
          <div className="w-fit flex items-center gap-4">
            {isSubscribed ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <button aria-label="Open Menu" className="cursor-pointer">
                    <Menu />
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-72 mr-12 py-4">
                  <DropdownMenuItem>
                    <Link
                      href="/home"
                      className="h-full w-full flex flex-row items-center p-0 m-0"
                      passHref
                    >
                      <Home className="mx-6 h-5 w-5 text-zinc-400 " />
                      <span>Home</span>
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={async () => {
                      toast.info('Redirecting to billing portal')
                      const data = await mutateAsync()

                      if (data?.billingPortalUrl) {
                        router.push(data?.billingPortalUrl)
                      } else {
                        toast.error(
                          "Sorry, we couldn't get you billing portal, please try again later."
                        )
                      }
                    }}
                  >
                    <CreditCard className="mx-6 h-5 w-5 text-zinc-400" />
                    <div
                    // href={billingPortalData?.billingPortalUrl ?? ""}
                    >
                      {isLoading ? <TinyLoader /> : <span>Billing</span>}
                    </div>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <Link href={'/pricing'}>
                <Button className="mr-4" variant={'zincLink'}>
                  Pricing
                </Button>
              </Link>
            )}
            <UserButton afterSignOutUrl="/" />
          </div>
        </SignedIn>
        <SignedOut>
          <div className="sm:hidden block">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Menu />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-72 mr-12 py-4">
                <Link
                  href="/auth/signin
                "
                  className="w-full"
                >
                  <DropdownMenuItem>
                    <UserRoundPlus className="mx-6 text-zinc-400 " />

                    <span>Sign in</span>
                  </DropdownMenuItem>
                </Link>
                <DropdownMenuItem className="bg-zinc-800 text-primary-foreground hover:bg-zinc-800/90 px-4 py-2 mt-1 ">
                  <LogIn className="ml-3 mr-6 text-zinc-400" />
                  <Link href="/auth/signup" className="w-full">
                    <span>Sign up</span>
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="flex items-center hidden sm:block">
            <Link href={'/auth/signin'}>
              <Button className="mr-4" variant={'zincLink'}>
                Sign in
              </Button>
            </Link>

            <Link
              className="h-8 rounded-md px-4 inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-sky-600 text-primary-foreground hover:bg-sky-600/90 "
              href={'/auth/signup'}
            >
              Sign up
            </Link>
          </div>
        </SignedOut>
      </div>
    </div>
  )
}
export default TopNav
