import { motion } from "framer-motion";
import { PropsWithChildren } from "react";

export const FramerStepWrapper = ({
  children,
  motionKey,
}: PropsWithChildren<{
  motionKey: string;
}>) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -40 }}
      key={motionKey}
      transition={{
        duration: 0.95,
        ease: [0.165, 0.84, 0.44, 1],
      }}
      className="w-3/4 lg:w-1/2 mx-auto px-2 lg:px-0  h-screen flex items-center flex-col"
    >
      {children}
    </motion.div>
  );
};
