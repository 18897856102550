import TopNav from '@/components/top-nav'
import { PropsWithChildren } from 'react'
import Footer from './footer'

export const NonAppNavWrapper = ({ children }: PropsWithChildren) => {
  return (
    <>
      <TopNav />
      {children}
      <Footer />
    </>
  )
}
