import { Loader2 } from "lucide-react";
import { FramerStepWrapper } from "./generic/framer-step-wrapper";

export const PageLoader = () => {
  return (
    <>
      <div className="select-none mt-[24rem] inline-flex items-center text-gray-500 mx-auto w-full">
        <div className="mx-auto inline-flex items-center">
          <Loader2 className="mx-auto h-4 w-4 animate-spin" />
          <span className="ml-2">Loading...</span>
        </div>
      </div>
    </>
  );
};

export const PageLoaderWithMessage = ({
  message,
  subMessage,
}: {
  message: string;
  subMessage?: string;
}) => {
  return (
    <FramerStepWrapper motionKey={message}>
      <div className="md:w-[24rem] text-center select-none mt-[24rem] inline-flex items-center text-gray-500 mx-auto grid">
        <div className="mx-auto inline-flex items-center text-lg">
          <Loader2 className="mx-auto h-5 w-5 animate-spin" />
          <span className="ml-2">{message}</span>
        </div>
        <p className="mx-auto mt-6 text-sm text-gray-400">{subMessage}</p>
      </div>
    </FramerStepWrapper>
  );
};

export const SmallLoader = () => {
  return (
    <div className="select-none flex justify-center items-center h-56">
      <div className="inline-flex items-center text-gray-500">
        <div className="w-fit">
          <Loader2 className="h-4 w-4 animate-spin" />
          <span className="ml-2">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export const TinyLoader = () => {
  return <Loader2 className="select-none h-4 w-4 animate-spin" />;
};
